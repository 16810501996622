import React from "react"

// Libraries
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Glide from "@glidejs/glide"

// Stylesheets
import "@glidejs/glide/dist/css/glide.core.min.css"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

const StyledTestimonials = styled.section`
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	border-bottom-width: 0;
	color: ${colors.white};
	width: 100%;
	background-color: ${colors.purpleLight};

  .bullets {
    display: flex;
    align-items: center;
	justify-content: center;
	margin: -4em 0 2.5em 0;
	padding: 0;
	height: 20px;
	align-items: flex-start;

    .bullet {
      width: 13px;
      height: 13px;
      position: relative;
      display: flex;
      margin: -10px 3px 0px 4px;
      border-radius: 50%;
      transition: all 0.3s;
      cursor: pointer;

	  ${breakpoint.medium`
	  	margin: 0 3px 0px 4px;
	  `}     

      &::before {
        content: "";
        width: 7px;
        height: 7px;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        border: 1px solid ${colors.white};
        border-radius: 50%;
      }

      &:hover,
      &.glide__bullet--active {
        background-color: ${colors.white};
      }

      &:last-child {
        margin-right: 0;
      }

    }
  }
  
  .header { 		
  		max-width: 100%;
		position: absolute;
		z-index: 10;
		text-align: center;
		width: 100%;
		align-self: flex-start;
		margin-top: 310px;

       ${breakpoint.xs`
			margin-top: 330px;
      `}     

	${breakpoint.medium`
		align-self: baseline;
		margin: 5rem auto;
  		max-width: 450px;
   		text-align: center;
		padding: 0 10px 0 25px;
		margin-left: 2rem;
	`}  	

     ${breakpoint.large`
     	margin-left: 6rem;
      `}     

     ${breakpoint.extraLarge`
     	margin-left: 10rem;
      `}     

     ${breakpoint.xxl`
     	margin-left: 15rem;
      `}     
    }
    
	 .footnote {
		 position: absolute!important;
		 bottom: 6px;
		 font-size: 8px;
		 line-height: 1.1em;
		 padding: 0px;
		max-width: 1100px;
		margin: 0px auto;
		text-align: center;

	${breakpoint.xs`
		 padding: 0px 15px;
		 bottom: 10px;
		 font-size: 9px;
	`}  

	${breakpoint.small`
		 padding: 0px 20px;
 		 bottom: 15px;
  		font-size: 10px;
	`}  

	${breakpoint.large`
		 padding: 0px 40px;
 		 bottom: 15px;
  		font-size: 11px;
	`}  
	}

    
`

const StyledTestimony = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding: 0;
    margin: 0;
    justify-content: center;
	height: 650px;
	background-color: ${colors.purpleDark};
	

	${breakpoint.medium`
   		justify-content: flex-start;
		height: 700px;
	`}  

	${breakpoint.extraLarge`
		height: 800px;
	`}  


  .testimony__content {
		width: 100%;
		max-width: 370px;
		position: absolute;
		margin: 1rem auto 5rem;
		padding: 0 10px;
		z-index: 10;
		flex-grow: 1;
		flex-shrink: 1;
		text-align: center;
		align-self: flex-start;
		margin-top: 390px;
		width: 100%!important;

       ${breakpoint.xs`
			max-width: 500px;
			margin-top: 420px;
      `}     

       ${breakpoint.small`
			max-width: 630px;
			margin-top: 410px;
      `}     

       ${breakpoint.medium`
		max-width: 450px;
       	align-self: flex-start;
       	margin-top: 12rem;
       	bottom: initial;
		margin-left: 2rem;
	`}  	

     ${breakpoint.large`
     	margin-left: 6rem;
      `}     

     ${breakpoint.extraLarge`
     	margin-left: 10rem;
      `}     

     ${breakpoint.xxl`
     	margin-left: 15rem;
      `}     


    .testimony {
      position: relative;
      font-size: 14px;
      line-height: 1.3em;
      
       ${breakpoint.small`
		font-size: 15px;
		line-height: 1.4em;
      `}     

	  ${breakpoint.medium`
	  font-size: 17px;
	  line-height: 1.5em;
	`}     

		&::before {
			content: "“";
			font-weight: 500;
		}
  
		&::after {
			content: "”";
			font-weight: 500;
		}     
   }


	.author {
		margin-top: 0.2rem;
		font-size: 13px;
		line-height: 1.5em;

	   ${breakpoint.small`
		font-size: 17px;
		line-height: 1.6em;
	  `}  
	}

}

  .testimony__image {
    margin: 0;
    top: 0;
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: start-top;
    width: 100%;
    position: relative;
    z-index: 10;
    opacity: 0.7;	 
    padding: 0px;
    filter:saturate(1);

	   ${breakpoint.medium`
    		align-self: center;
	  `}  

  }

`

class Carousel extends React.Component {
  constructor(props) {
    super(props)
    this.myRef = React.createRef()
  }

  componentDidMount = () => {
    const carousel = new Glide(this.myRef.current, {
      autoplay: 10000,
      animationTimingFunc: 'ease-in',
      type: "carousel"
    })
    carousel.mount()
  }

  render = props => (
    <div className="glide" ref={this.myRef}>
      <div className="glide__track" data-glide-el="track">
        <div className="glide__slides">{this.props.children}</div>
		  <div className="bullets glide__bullets" data-glide-el="controls[nav]">
			<button type="bullet" className="bullet" data-glide-dir="=0" aria-label="Patient M"></button>
			<button type="bullet" className="bullet" data-glide-dir="=1" aria-label="Patient G"></button>
			<button type="bullet" className="bullet" data-glide-dir="=2" aria-label="Patient K"></button>
			<button type="bullet" className="bullet" data-glide-dir="=3" aria-label="Patient B"></button>
			<button type="bullet" className="bullet" data-glide-dir="=4" aria-label="Patient P"></button>
			<button type="bullet" className="bullet" data-glide-dir="=5" aria-label="Patient R"></button>
			<button type="bullet" className="bullet" data-glide-dir="=6" aria-label="Patient W"></button>
			<p className="footnote">*These quotes are from women who have had STI(s) and who, in market research shared their current experience, 
			were then shown the Visby Medical Sexual Health Test and asked how having an accurate result at the time of the visit might impact them. 
			The test has not been used with these women. All images are stock photos, used for illustrative purposes only, posed by models.</p>
		  </div>
      </div>
    </div>
  )
}

const Testimonials = () =>  {
  return (  
    <StyledTestimonials>
		<div className="header"><h2>What are people<br />saying</h2></div>
        <Carousel>
            <div className="glide__slide">
			  <StyledTestimony key="testimonial1">
				<div className="testimony__image">
				<StaticImage
					src="../../../assets/images/sexual-health-test/patients/testimonial1.jpg"
					layout="fullWidth"
					alt="What patients are saying"
					className="bgimage"
					style={{
						position: "initial",
						backgroundPosition: "center center",
						backgroundRepeat: "no-repeat",
						}}
				/>
				</div>
				<div className="testimony__content">
				  <p className="testimony">I feel like the consultation and the treatment part of it just kind of brings more connection and more trust because then it forces the doctor to know you, know your concerns at a better, deeper state. And it forces you to kind of have those talks with that doctor and maybe now you’re telling her well okay, or I thought this, but you know, you’re just more open to have that conversation.</p>
					<p className="author">— Patient "M"</p>
				</div>
			  </StyledTestimony>
            </div>
            <div className="glide__slide">
			  <StyledTestimony key="testimonial2">
				<div className="testimony__image">
				<StaticImage
					src="../../../assets/images/sexual-health-test/patients/testimonial2.jpg"
					layout="fullWidth"
					alt="What patients are saying"
					className="bgimage"
					style={{
						backgroundSize: "contain",
						position: "initial",
						backgroundPosition: "center center",
						backgroundRepeat: "no-repeat",
						}}
				/>
				</div>
				<div className="testimony__content">
				  <p className="testimony">Super fast! This is just like a better way to get your results and it’s not anxious….Knowing that you’re going to get the results, the same day—it’s a good feeling…feeling relieved. </p>
					<p className="author">— Patient "G"</p>
				</div>
			  </StyledTestimony>
			</div>
            <div className="glide__slide">
			  <StyledTestimony key="testimonial3">
				<div className="testimony__image">
				<StaticImage
					src="../../../assets/images/sexual-health-test/patients/testimonial3.jpg"
					layout="fullWidth"
					alt="What patients are saying"
					className="bgimage"
					style={{
						backgroundSize: "contain",
						position: "initial",
						backgroundPosition: "center center",
						backgroundRepeat: "no-repeat",
						}}
				/>
				</div>
				<div className="testimony__content">
				  <p className="testimony">It’s a good thing that I’m going to get the results before I leave.</p>
					<p className="author">— Patient "K"</p>
				</div>
			  </StyledTestimony>
			</div>
            <div className="glide__slide">
			  <StyledTestimony key="testimonial4">
				<div className="testimony__image">
				<StaticImage
					src="../../../assets/images/sexual-health-test/patients/testimonial4.jpg"
					layout="fullWidth"
					alt="What patients are saying"
					className="bgimage"
					style={{
						backgroundSize: "contain",
						position: "initial",
						backgroundPosition: "center center",
						backgroundRepeat: "no-repeat",
						}}
				/>
				</div>
				<div className="testimony__content">
				  <p className="testimony">A 10 out of 10 compared to the normal process for sure!</p>
					<p className="author">— Patient "B"</p>
				</div>
			  </StyledTestimony>
			</div>
            <div className="glide__slide">
			  <StyledTestimony key="testimonial5">
				<div className="testimony__image">
				<StaticImage
					src="../../../assets/images/sexual-health-test/patients/testimonial5.jpg"
					layout="fullWidth"
					alt="What patients are saying"
					className="bgimage"
					style={{
						backgroundSize: "contain",
						position: "initial",
						backgroundPosition: "center center",
						backgroundRepeat: "no-repeat",
						}}
				/>
				</div>
				<div className="testimony__content">
				  <p className="testimony">It’s a relief, knowing that I don’t have to wait. I can find out what’s going on, and then get treated right then and there. It’s just like one and done—which I think is perfect. That’s how it should be. Literally perfect!</p>
					<p className="author">— Patient "P"</p>
				</div>
			  </StyledTestimony>
			</div>
            <div className="glide__slide">
			  <StyledTestimony key="testimonial6">
				<div className="testimony__image">
				<StaticImage
					src="../../../assets/images/sexual-health-test/patients/testimonial6.jpg"
					layout="fullWidth"
					alt="What patients are saying"
					className="bgimage"
					style={{
						backgroundSize: "contain",
						position: "initial",
						backgroundPosition: "center center",
						backgroundRepeat: "no-repeat",
						}}
				/>
				</div>
				<div className="testimony__content">
				  <p className="testimony">That is the best idea ever! That’s just like awesome!</p>
					<p className="author">— Patient "R"</p>
				</div>
			  </StyledTestimony>
			</div>
            <div className="glide__slide">
			  <StyledTestimony key="testimonial7">
				<div className="testimony__image">
				<StaticImage
					src="../../../assets/images/sexual-health-test/patients/testimonial7.jpg"
					layout="fullWidth"
					alt="What patients are saying"
					className="bgimage"
					style={{
						backgroundSize: "contain",
						position: "initial",
						backgroundPosition: "center center",
						backgroundRepeat: "no-repeat",
						}}
				/>
				</div>
				<div className="testimony__content">
				  <p className="testimony">This works out perfectly!</p>
					<p className="author">— Patient "W"</p>
				</div>
			  </StyledTestimony>
			</div>
        </Carousel>
    </StyledTestimonials>
  )
}

export default Testimonials
